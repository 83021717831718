<template>
  <div class="">
    <vue-data-table
      :cols="[
        {
          header: 'Title',
          value: 'title',
        },
        {
          header: 'Slug',
          value: 'slug',
        },
        {
          header: 'Added At',
          value: 'created_at',
          sort: true
        },
        {
          header: 'Last Modified',
          value: 'updated_at',
          sort: true
        },
        {
          header: 'Actions',
          value: 'actions'
        }
      ]"
      path="metadata"
      :request-params="{
        withImages: true,
        type: 'post'
      }"
    >
      <template #header-left>
        <vue-button
          :icon="['fas', 'plus-circle']"
          @click="$router.push({ name: 'posts.new' })"
        >
          Create Post
        </vue-button>
      </template>
      <template v-slot:item.title="row">
        {{ row.value.title }}
      </template>
      <template v-slot:item.slug="row">
        {{ row.value.slug }}
      </template>
      <template v-slot:item.created_at="row">
        {{ $momentDateTimeHuman(row.created_at) }}
      </template>
      <template v-slot:item.updated_at="row">
        {{ $momentDateTimeHuman(row.updated_at) }}
      </template>
      <template v-slot:item.actions="row">
        <vue-link
          :to="{ path: `/posts/${row.id}` }"
          :icon="['fas', 'edit']"
          :aria-label="`Edit Post: ${row.name}`"
          text
          sm
          class="inline-flex cursor-pointer"
        />
      </template>
    </vue-data-table>
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'Posts'
}
</script>
